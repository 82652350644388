import colors from "./colors";
import text from "./text";

const mainTheme = {
    colors,
    space: [0, 20, 40, 50, 60, 80, 120, 200, 280],
    breakpoints: ["425px", "768px", "1000px", "1366px", "1920px"],
    fonts: {
        body: "Spinnaker",
        heading: "Source Sans Pro",
        title: "Lato"
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
    fontWeight: {
        body: 400,
        heading: 600,
        title: 900
    },
    lineHeights: {
        body: 1.5,
        heading: 1.125,
        title: 1
    },
    letterSpacings: {
        body: "normal",
        spaced: "0.1em"
    },
    selection: {
        color: "#fff",
        background: "#000"
    },
    text,
    styles: {
        root: {
            overflowX: "hidden",
            bg: "white",
            m: 0,
            p: 0
        }
    }
};

export default mainTheme;

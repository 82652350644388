const text = {
    body: {
        alt: {
            color: "muted",
            fontSize: [2, 2, 3, 3, 4, 4],
            fontFamily: "body",
            lineHeight: "normal",
            letterSpacing: "normal",
            m: 0
        },
        main: {
            color: "black",
            fontSize: [2, 2, 3, 3, 4, 4],
            fontFamily: "body",
            lineHeight: "normal",
            letterSpacing: "normal",
            m: 0
        }
    },
    heading: {
        sub: {
            fontSize: [3, 3, 4, 4, 6, 6],
            color: "black",
            fontFamily: "heading",
            lineHeight: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            m: 0
        },
        main: {
            fontSize: [4, 4, 5, 5, 7, 7],
            color: "black",
            fontFamily: "heading",
            lineHeight: "normal",
            fontWeight: 600,
            letterSpacing: "normal",
            m: 0
        }
    },
    title: {
        main: {
            color: "black",
            fontSize: [5, 6, 7, 7, 8, 8],
            fontFamily: "title",
            lineHeight: "normal",
            fontWeight: 400,
            m: 0,
            letterSpacing: "normal"
        }
    },
    outline: {
        color: "transparent",
        textShadow: "none",
        WebkitTextStroke: "1px #000"
    },
    underline: {
        "&:after": {
            content: '""',
            position: "absolute",
            display: "block",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            height: ["2px", "3px"],
            bg: "black",
            mt: "5px",
            transition: "0.4s ease"
        }
    },
    vertical: {
        height: ["130px", "150px", "180px", "220px"],
        position: "absolute",
        transform: "rotate(90deg)",
        font: "bold 70px Arial"
    }
};

export default text;

const colors = {
    primary: "",
    secondary: "",
    white: "#fff",
    offWhite: "#fbf9f8",
    black: "#000",
    muted: "#676461",
    border: "#ddd",
    lightgrey: "#eee"
};

export default colors;
